import { ConnectResult, SaleorManager } from "@the-volte/svc-core-sdk";
import { GetShop } from "@the-volte/svc-core-sdk/lib/queries/gqlTypes/GetShop";
import { getShop } from "@the-volte/svc-core-sdk/lib/queries/shop";
import { GetServerSideProps, GetStaticProps } from "next";

import {
  apiUrl,
  channelSlug,
  nextCFClientId,
  nextCFClientSecret,
  productsPerPage,
} from "@app/constants";
import {
  convertSortByFromString,
  convertToAttributeScalar,
} from "@app/core/utils";
import { ArticleViewProps } from "@app/views/Article";
import { CategoryViewProps } from "@app/views/Category";
import { SearchViewProps } from "@app/views/Search";
import { searchResultsQuery } from "@app/views/Search/queries";
import { Attribute } from "@graphql/gqlTypes/Attribute";
import {
  FeaturedProductsQuery,
  FeaturedProductsQuery_collection,
  FeaturedProductsQuery_collection_products_edges_node,
  FeaturedProductsQueryVariables,
} from "@graphql/gqlTypes/FeaturedProductsQuery";
import {
  ShopAttributesQuery,
  ShopAttributesQueryVariables,
} from "@graphql/gqlTypes/ShopAttributesQuery";
import { ShopMenusQuery } from "@graphql/gqlTypes/ShopMenusQuery";
import {
  attributedProductsQuery,
  attributeValuesQuery,
  categoryProductsQuery,
  featuredProductsQuery,
  lenderDetailsQuery,
  lenderProductsQuery,
  MENU_FOOTER_QUERY,
  shopAttributesQuery,
  shopMenusQuery,
} from "@graphql/queries";

import { FilterQuerySet } from "./collections";
import { getDesignerFromSlug } from "./designers";
import { PriceFilterType } from "./filterData";
import { extractMetadata } from "./misc";

let CONNECTION: ConnectResult | null = null;

interface MetaDataStructure {
  slug: string;
  values: any[];
}

type GetLenderDetailsData = {
  id: string;
  name: string;
  rating: number;
  avatar: string | null;
  user: any;
  isActiveCloset: boolean;
  lenderOrdersMade: any;
  lenderAggregateRating?: {
    ratingValue: number;
    ratingCount: number;
  };
};

export const getSaleorApi = async () => {
  if (!CONNECTION) {
    const manager = new SaleorManager(
      { apiUrl, channel: channelSlug },
      {
        options: {
          ssrMode: true,
          headers: {
            "CF-Access-Client-Id": nextCFClientId,
            "CF-Access-Client-Secret": nextCFClientSecret,
          },
        },
      }
    );
    CONNECTION = await manager.connect();
  }

  return CONNECTION;
};

export type FeaturedProducts = {
  products: FeaturedProductsQuery_collection_products_edges_node[];
} & Pick<FeaturedProductsQuery_collection, "name" | "backgroundImage">;

export const getFeaturedProducts = async (): Promise<FeaturedProducts> => {
  const { apolloClient } = await getSaleorApi();
  const { data } = await apolloClient.query<
    FeaturedProductsQuery,
    FeaturedProductsQueryVariables
  >({
    query: featuredProductsQuery,
    variables: { channel: channelSlug },
    fetchPolicy: "no-cache", // avoid caching of ISR pages - Apollo cache is long lasting!
  });

  return {
    ...data.collection!,
    products: data.collection!?.products!?.edges.map((e) => e.node) || [],
  };
};

/**
 * @name getAttributedProducts
 * @description Used during SSR to return the resulting products for chosen
 * attributes. A special case of this usage is when a designer is supplied (ie.
 * designer page). Returned object is deliberately left in query reasult shape so
 * it can be processed by the same functions that are used in browser.
 */
type GetAttributeProductsParams = {
  attributes: MetaDataStructure[];
  location?: string[];
  price?: { gte: number | null; lte: number | null };
  prices?: { gte: number | null; lte: number | null }[];
};
export const getAttributedProducts = async ({
  attributes = [],
  location = [],
  price = { gte: null, lte: null },
  prices = [],
}: GetAttributeProductsParams) => {
  const { apolloClient } = await getSaleorApi();

  const { data } = await apolloClient.query({
    query: attributedProductsQuery,
    variables: { channel: channelSlug, attributes, location, price, prices },
    fetchPolicy: "no-cache", // avoid caching of ISR pages - Apollo cache is long lasting!
  });

  return data;
};

/**
 * @name getCategoryProducts
 * @description Used during SSR to return the resulting products for a specific
 * category. The returned object is deliberately left in query reasult shape so
 * it can be processed by the same functions that are used in browser.
 */
const getCategoryProducts = async (
  categoryId,
  filters,
  sortBy,
  after,
  fetchPolicy
) => {
  const { apolloClient } = await getSaleorApi();

  const decodedFilters = FilterQuerySet.decode(filters);
  const attributes = convertToAttributeScalar(decodedFilters);

  const { data } = await apolloClient.query({
    query: categoryProductsQuery,
    variables: {
      channel: channelSlug,
      after,
      filters: {
        attributes,
        categories: [categoryId],
      },
      first: productsPerPage,
      sortBy: convertSortByFromString(sortBy),
    },
    fetchPolicy,
  });

  return data;
};

export const getLenderProducts = async (lenderSlug: string) => {
  const { apolloClient } = await getSaleorApi();

  const { data } = await apolloClient.query({
    query: lenderProductsQuery,
    variables: { channel: channelSlug, lenderSlug },
    fetchPolicy: "no-cache", // avoid caching of ISR pages - Apollo cache is long lasting!
  });

  return data;
};

export const getLenderDetails = async (lenderSlug: string) => {
  const { apolloClient } = await getSaleorApi();

  const { data } = await apolloClient.query<{ lender?: GetLenderDetailsData }>({
    query: lenderDetailsQuery,
    variables: { slug: lenderSlug },
    fetchPolicy: "no-cache", // avoid caching of ISR pages - Apollo cache is long lasting!
  });

  return data;
};

const getKeywordSearchedProducts = async (keyword) => {
  const { apolloClient } = await getSaleorApi();

  const { data } = await apolloClient.query({
    query: searchResultsQuery,
    variables: { channel: channelSlug, query: keyword, pageSize: 20 },
    fetchPolicy: "no-cache", // avoid caching of ISR pages - Apollo cache is long lasting!
  });

  return data;
};

const getAttributeValues = async (attribute) => {
  const { apolloClient } = await getSaleorApi();
  const fetchPolicy = attribute === "designer" ? "no-cache" : "cache-first";
  const { data } = await apolloClient.query({
    query: attributeValuesQuery,
    variables: { attribute },
    fetchPolicy,
  });
  return data?.attributes?.edges.map((e) => e.node) || [];
};

export const getShopAttributes = async ({
  categoryId = null,
  collectionId = null,
}: {
  categoryId?: string | null;
  collectionId?: string | null;
}): Promise<Attribute[]> => {
  const { apolloClient } = await getSaleorApi();
  const { data } = await apolloClient.query<
    ShopAttributesQuery,
    ShopAttributesQueryVariables
  >({
    query: shopAttributesQuery,
    variables: {
      categoryId,
      collectionId,
      channel: channelSlug,
    },
    fetchPolicy: "no-cache",
  });
  return data?.attributes?.edges?.map((e) => e.node) || [];
};

export type ShopConfig = ShopMenusQuery & { shopConfig: GetShop["shop"] };

const getApiShopConfig = async () => {
  const uri = process.env?.GATEWAY_SVC_URI || "";

  const domain = (process.env?.SITE_URL || "").replace("https://", "");
  const isDev = uri.includes("staging") || uri.includes("localhost");
  const headers = {
    "Content-Type": "application/json",
    ...(isDev && {
      "CF-Access-Client-Id": nextCFClientId,
      "CF-Access-Client-Secret": nextCFClientSecret,
    }),
  };

  return fetch(uri, {
    method: "POST",
    headers,
    body: JSON.stringify({
      query: MENU_FOOTER_QUERY,
      variables: { headerType: "header", footerType: "footer", domain },
    }),
  });
};

export const getShopConfig = async (): Promise<any> => {
  const { apolloClient } = await getSaleorApi();

  const [{ shop }, shopConfig, menuQuery] = await Promise.all([
    apolloClient
      .query<ShopMenusQuery>({
        query: shopMenusQuery,
        fetchPolicy: "no-cache",
      })
      .then(({ data }) => data),
    apolloClient
      .query<GetShop>({
        query: getShop,
        fetchPolicy: "no-cache",
        variables: {
          channel: channelSlug,
        },
      })
      .then(({ data }) => data?.shop),
    getApiShopConfig()
      .then((r) => r.json())
      .then(({ data }) => data)
      .catch(() => null),
  ]);

  return {
    footer: menuQuery?.footer,
    mainMenu: menuQuery?.header,
    shopConfig,
    shop,
  };
};

export enum PageTypes {
  DESIGNER = "designer",
  FILTER = "filter",
  LOCATION = "location",
  INDEX = "index",
  SEARCH = "product-search",
  CONTACT = "contact",
}

export const parseMetaFilterValue = (v) => {
  if (!v.startsWith("[")) {
    return [v];
  }
  const parsed = JSON.parse(v.replace(/'/g, '"'));
  return parsed;
};

const getPageProps = async (
  slug: string,
  ssr: boolean,
  shouldRevalidate = true
) => {
  const { api } = await getSaleorApi();
  const { data } = await api.pages.getDetails({ slug });
  if (!data?.pageType) {
    return {
      notFound: true,
      ...(!ssr && shouldRevalidate && { revalidate: 600 }),
    };
  }

  let pageProducts = null;
  let other = null;
  let priceGte: any[] | null = null;
  let priceLte: any[] | null = null;
  const presetAttributeFilter: MetaDataStructure[] = [];
  if (data?.pageType?.slug === PageTypes.DESIGNER) {
    const designerSlug = getDesignerFromSlug(slug);
    [pageProducts] = await Promise.all([
      getAttributedProducts({
        attributes: [{ slug: "designer", values: [designerSlug] }],
      }),
    ]);
  }
  if (data?.pageType?.slug === PageTypes.FILTER) {
    const priceMetaData: MetaDataStructure[] = [];
    data.metadata.forEach(
      (x) =>
        x?.key &&
        (x?.key.startsWith("price")
          ? priceMetaData
          : presetAttributeFilter
        ).push({
          slug: x.key,
          values: parseMetaFilterValue(x.value),
        })
    );

    ({ priceGte, priceLte } = extractMetadata({
      slugs: ["priceGte", "priceLte"],
      metaData: priceMetaData,
    }));

    [pageProducts] = await Promise.all([
      getAttributedProducts({
        attributes: presetAttributeFilter,
        price: {
          gte: null,
          lte: null,
        },
        prices:
          priceGte || priceLte
            ? [
                {
                  gte: priceGte ? priceGte[0] : null,
                  lte: priceLte ? priceLte[0] : null,
                },
              ]
            : [],
      }),
    ]);
  }

  if (data?.pageType?.slug === PageTypes.INDEX) {
    const attr = data?.metadata?.find((x) => x?.key === "attribute")?.value;
    [other] = await Promise.all([getAttributeValues(attr)]);
  }

  if (data?.pageType?.slug === PageTypes.SEARCH) {
    const keywords = data?.metadata?.find((x) => x?.key === "keywords")?.value;
    [other] = await Promise.all([getKeywordSearchedProducts(keywords)]);
  }

  let locationData = [];
  if (data?.pageType?.slug === PageTypes.LOCATION) {
    const postcodes = data.metadata.find((x) => x?.key === "postcodes")?.value;
    locationData = postcodes ? parseMetaFilterValue(postcodes) : [];

    // if the location does not contain at least one pair of `gte/lte`, consider it to be invalid and
    // dont perform the products query
    if (locationData.find((x) => !!x.gte && !!x.lte)) {
      [pageProducts] = await Promise.all([
        getAttributedProducts({ location: locationData }),
      ]);
    }
  }

  const presetPriceFilters: PriceFilterType = {
    gte: parseInt(priceGte?.[0], 10) || null,
    lte: parseInt(priceLte?.[0], 10) || null,
    slug: "",
  };

  return {
    props: {
      data: {
        article: data || null,
        featuredProducts: null,
        products: pageProducts,
        presetAttributeFilter: presetAttributeFilter.reduce((acc, cur) => {
          if (cur?.slug && cur?.values) {
            acc[cur.slug] = cur.values;
          }
          return acc;
        }, {}),
        presetPriceFilters,
        other,
      },
      params: { slug },
    },
    ...(!ssr && shouldRevalidate && { revalidate: 10 }),
  };
};

/**
 * @name getPageStaticProps
 * @description get the data to render a content-managed page
 */
export const getPageStaticProps: GetStaticProps<
  ArticleViewProps,
  ArticleViewProps["params"]
> = async ({ params: { slug, shouldRevalidate } }) => {
  const pageProps = await getPageProps(slug, false, shouldRevalidate);

  return pageProps;
};

export const getPageServerSideProps: GetServerSideProps<
  ArticleViewProps,
  ArticleViewProps["params"]
> = async ({ params: { slug } }) => {
  const pageProps = await getPageProps(slug, true);

  return pageProps;
};

/**
 * @name getCategoryServerSideProps
 * @description get the data to render a category page, used on /category/:slug
 * Have to use SSR becuase SSG doesn't yet support query
 * https://github.com/vercel/next.js/discussions/17269
 */
export const getCategoryServerSideProps: GetServerSideProps<
  CategoryViewProps,
  CategoryViewProps["params"]
> = async ({ params: { slug }, query: { filters, sortBy, after } }) => {
  let categoryData = null;
  const { api } = await getSaleorApi();
  const { data: details } = await api.categories.getDetails({ slug });

  if (details) {
    const { id } = details;

    const [featuredProducts, products, ancestors] = await Promise.all([
      getFeaturedProducts(),
      getCategoryProducts(id, filters, sortBy, after, undefined),
      api.categories.getAncestors({ first: 5, id }).then(({ data }) => data),
    ]);
    categoryData = {
      details,
      ancestors,
      featuredProducts,
      products,
      id,
    };
  }

  return {
    props: {
      data: categoryData,
      params: { slug },
    },
  };
};

/**
 * @name getCategoryStaticProps
 * @description get the data to render a category page, used for /dresses
 * Note the /dresses with query param gets rewritten to /category/dresses (SSR)
 */
// TODO: Remove this once tested and verified in staging
// export const getCategoryStaticProps: GetStaticProps<
//   CategoryViewProps,
//   CategoryViewProps["params"]
// > = async ({ params: { slug } }) => {
//   let categoryData = null;
//   const { api } = await getSaleorApi();
//   const { data: details } = await api.categories.getDetails({ slug });

//   if (details) {
//     const { id } = details;
//     const filters = "";
//     const sortBy = "";
//     const after = null;

//     const [products, ancestors] = await Promise.all([
//       getCategoryProducts(id, filters, sortBy, after, "no-cache"), // No cache for SSG ISR
//       api.categories.getAncestors({ first: 5, id }).then(({ data }) => data),
//     ]);

//     categoryData = {
//       details,
//       ancestors,
//       products,
//       id,
//     };
//   }

//   return {
//     revalidate: 60,
//     props: {
//       data: categoryData,
//       params: { slug },
//     },
//   };
// };
/**
 * @name getSearchStaticProps
 * @description get the data to render search results
 */
export const getSearchStaticProps: GetStaticProps<
  SearchViewProps,
  SearchViewProps["params"]
> = async () => {
  let categoryData = null;
  const { api } = await getSaleorApi();
  const { data: details } = await api.categories.getDetails({
    slug: "dresses",
  });

  if (details) {
    const { id } = details;

    const [attributes] = await Promise.all([
      getShopAttributes({ categoryId: id }),
    ]);

    categoryData = {
      products: [],
      attributes,
      id,
    };
  }

  return {
    revalidate: 60,
    props: {
      data: categoryData,
    },
  };
};
